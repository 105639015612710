<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 信息区域 -->
        <div class="wish_table">
            <div class="wish_box">
                <div class="mark">
                    <span>注：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站</span>
                    <span>申明：使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍</span>
                </div>
                <div class="userbox">
                    <div class="type">
                        <span class="vip"><i class="iconfont">&#xe6ba;</i>{{User.wishCategory === 1?'位点法':'位次法'}}</span>
                        <span class="tname">志愿表</span>
                        <!-- <span class="tname">[21854854855465]</span> -->
                        <span class="yname">{{User.Year}}年 {{User.ProvinceName}} 高考志愿模拟填报</span>
                    </div>
                    <div class="info">
                        <span class="exam">
                            <span>我的成绩：{{User.Score}} 分</span>
                            <!-- <span v-if="User.SubjectList">选科：{{ User.SubjectList.join(',') }}</span> -->
                            <span v-if="User.SubjectType">
                                <span class="area">首选:{{User.SubjectType==1?'物理':'历史'}}</span>
                                <span v-if="User.SubjectList" class="area">再选:{{User.SubjectList.join(',')}}</span>
                            </span>
                            <span v-else>
                                <span v-if="User.SubjectList" class="area">{{User.SubjectList.join(',')}}</span>
                            </span>
                            <span>批次：{{SelectRecommendBatch.BatchName}}</span>
                            <span v-if="RecommendBatch.Rank">位次：{{RecommendBatch.Rank||'-'}}</span>
                            <!-- <span>科类：首选:物理；再选:化学,生物</span> -->
                        </span>
                    </div>
                    <div class="bott">
                        <span @click.prevent="WishDownload"><i class="el-icon-download"></i>下载</span>
                        <span @click.prevent="WishPrint"><i class="el-icon-printer"></i>打印</span>
                        <span @click.prevent="WishEdit"><i class="el-icon-edit"></i>修改</span>
                        <span @click.prevent="WishSave"><i class="el-icon-document-checked"></i>保存</span>
                    </div>
                </div>
                <div class="theadtitle">
                    <span class="txt">您填报的志愿数量为 <b>{{ReportCheckData.length}}</b> 个</span>
                    <div class="sort">
                        <span class="tit">
                            <span class="tt" style="border-left:none;"><i class="ico iconfont" v-if="User.wishCategory==0">&#xe6ed;</i><i class="ico iconfont" v-else>&#xec66;</i>{{User.wishCategory==0?'按录取概率':'按预测分'}}</span>
                            <span class="icon">
                                <i class="el-icon-caret-top" :class="selected==11?'on':''" title="升序" @click.prevent="FastSortCheck(11)"></i>
                                <i class="el-icon-caret-bottom" :class="selected==12?'on':''" title="降序" @click.prevent="FastSortCheck(12)"></i>
                            </span>
                            <div class="clear"></div>
                        </span>
                        <span class="tit">
                            <span class="tt"><i class="ico iconfont">&#xe62f;</i>按院校排名</span>
                            <span class="icon">
                                <i class="el-icon-caret-top" :class="selected==21?'on':''" title="排名从高到低" @click.prevent="FastSortCheck(21)"></i>
                                <i class="el-icon-caret-bottom" :class="selected==22?'on':''" title="排名从低到高" @click.prevent="FastSortCheck(22)"></i>
                            </span>
                            <div class="clear"></div>
                        </span>
                        <span class="tit">
                            <span class="tt"><i class="ico iconfont">&#xe658;</i>按保研率</span>
                            <span class="icon">
                                <i class="el-icon-caret-top" :class="selected==31?'on':''" title="升序" @click.prevent="FastSortCheck(31)"></i>
                                <i class="el-icon-caret-bottom" :class="selected==32?'on':''" title="降序" @click.prevent="FastSortCheck(32)"></i>
                            </span>
                            <div class="clear"></div>
                        </span>
                        <span class="tit">
                            <span class="tt"><i class="ico iconfont">&#xe709;</i>按读研率</span>
                            <span class="icon">
                                <i class="el-icon-caret-top" :class="selected==41?'on':''" title="升序" @click.prevent="FastSortCheck(41)"></i>
                                <i class="el-icon-caret-bottom" :class="selected==42?'on':''" title="降序" @click.prevent="FastSortCheck(42)"></i>
                            </span>
                            <div class="clear"></div>
                        </span>
                        <span class="tit">
                            <span class="tt"><i class="ico iconfont">&#xe86d;</i>按学科评估</span>
                            <span class="icon">
                                <i class="el-icon-caret-top" :class="selected==51?'on':''" title="升序" @click.prevent="FastSortCheck(51)"></i>
                                <i class="el-icon-caret-bottom" :class="selected==52?'on':''" title="降序" @click.prevent="FastSortCheck(52)"></i>
                            </span>
                            <div class="clear"></div>
                        </span>
                        <div class="clear"></div>

                        <!-- <el-radio-group v-model="SortGroupType" @change="SortGroupTypeChange" v-removeAriaHidden>
                            <el-radio label="1"></el-radio>
                        </el-radio-group> -->
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>

        <!-- 志愿列表（院校+专业） -->
        <div class="wish_t_h" v-if="CollegeOrMajorGroupId==1">
            <div class="wish_t_fixed" :class="TableHeaderFixed">
                <div class="wish_head">
                    <div class="htable">
                        <div class="head">
                            <div class="td">序号</div>
                            <div class="td">院校</div>
                            <div class="td">专业</div>
                            <div class="td">{{SelectRecommendBatch.PlanYear}} 招生计划</div>
                            <div v-if="SelectRecommendBatch.YearList" class="td">
                                <div class="t">历年录取</div>
                                <div class="year">
                                    <div class="y">{{SelectRecommendBatch.YearList[0]}}年</div>
                                    <div class="y">{{SelectRecommendBatch.YearList[1]}}年</div>
                                    <div class="y">{{SelectRecommendBatch.YearList[2]}}年</div>
                                </div>
                            </div>
                            <div class="td">排序</div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wish_t_l" v-if="CollegeOrMajorGroupId==1">
            <div class="wish_t_m">
                <div class="wtable">
                    <div class="table">
                        <table cellspacing="0" style="width:100%;" id="WishSortTable">
                            <tr v-for="(item, index) in ReportCheckData" :key="item.RecommendCollegeId">
                                <td>
                                    <i class="el-icon-top" title="向上排序" @click.prevent="CollegeWishSortUp(item, index)"></i>
                                    <span class="num" title="修改排序" @click.prevent="UpdateSort(index+1, index)">{{index+1}}</span>
                                    <i class="el-icon-bottom" title="向下排序" @click.prevent="CollegeWishSortDown(item, index)"></i>
                                    <i class="el-icon-delete" title="移除院校及专业" @click.prevent="CollegeWishRemove(item, index)"></i>
                                </td>
                                <td class="move">
                                    <div class="c_name">{{item.CollegeName}}</div>
                                    <span class="score" v-if="User.wishCategory===1">预测分：{{item.PredictScore?item.PredictScore+'分':'-'}}<span class="isadjuststr">{{item.IsAdjust?'服从调剂':'不服从调剂'}}</span></span>
                                    <span class="score" v-else>录取概率：{{item.AdmissionProbability?item.AdmissionProbability+'%':'-'}}<span class="isadjuststr">{{item.IsAdjust?'服从调剂':'不服从调剂'}}</span></span>
                                    <span v-show="item.CollegeRankNum" class="tips-words">{{item.CollegeRankNum+'['+item.CollegeRankCategoryName+']'}}</span>
                                    <div class="s_txt">
                                        <div class="tips-words">
                                            <span v-show="item.PlanPersons">计划人数：{{ item.PlanPersons }}</span>
                                            <span v-show="item.SubjectsCondition">{{ item.SubjectsCondition }}</span>
                                        </div>
                                        <span>代码：{{item.CollegeCode}}</span>
                                        <span v-show="item.GroupCode">专业组代码：{{ item.GroupCode }}</span>
                                        <div class="tips-words">
                                            <span v-show="item.PostgraduateRate">读研率：{{ item.PostgraduateRate }}%</span>
                                            <span v-show="item.PostgraduateRecommendationRate">保研率：{{ item.PostgraduateRecommendationRate }}%</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <table cellspacing="0" style="width:100%;">
                                        <tr v-for="(majorItem, majorIndex) in item.SelectMajor" :key="majorItem.RecommendMajorId">
                                            <td>
                                                <div class="major">
                                                    <div class="name">{{majorItem.MajorName}}</div>
                                                    <div class="mx">{{majorItem.MajorRemark}}</div>
                                                    <div class="mx">
                                                        <span>代码：{{majorItem.MajorCode}}</span>
                                                        <span v-if="User.wishCategory===1">预测分：{{majorItem.PredictScore?majorItem.PredictScore+'分':'-'}}</span>
                                                        <span v-else>录取概率：{{majorItem.AdmissionProbability?majorItem.AdmissionProbability+'%':'-'}}</span>
                                                        <div class="tips-words">
                                                            <span v-show="majorItem.PostgraduateRate">读研率：{{ majorItem.PostgraduateRate }}%</span>
                                                            <span v-show="majorItem.PostgraduateRecommendationRate">保研率：{{ majorItem.PostgraduateRecommendationRate }}%</span>
                                                            <span v-show="majorItem.IsPostgraduateMaster">硕士点：{{ majorItem.IsPostgraduateMaster }}</span>
                                                            <span v-show="majorItem.IsPostgraduateDoctoral">博士点：{{ majorItem.IsPostgraduateDoctoral }}</span>
                                                            <span v-show="majorItem.MajorRankNum">专业排名：{{majorItem.MajorRankNum}}</span>
                                                            <span v-show="majorItem.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{majorItem.LearnSubjectEvaluateLevel}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-show="majorItem.PlanPersons" class="person"><span>{{majorItem.PlanPersons}}</span> 人</div>
                                                <div>
                                                    <span v-show="majorItem.LearningYears" class="md">{{majorItem.LearningYears}}年</span>
                                                    <span v-show="majorItem.LearningCost" class="md"> ￥{{majorItem.LearningCost}}</span>
                                                </div>
                                                <div v-show="majorItem.SubjectsCondition" class="md">{{majorItem.SubjectsCondition}}</div>
                                            </td>
                                            <td>
                                                <div class="ls">
                                                    <span>录取人数：{{majorItem.PersonsOfYear1}}</span>
                                                    <span>最低分：{{majorItem.ScoreLowOfYear1}}</span>
                                                    <span>最低位次：{{majorItem.RankLowOfYear1}}</span>
                                                    <span class="tips">
                                                        <span class="tips-text">
                                                            <!-- <i class="icon el-icon-question"></i> -->
                                                            <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                            <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                                        </span>
                                                        <span>等效分差：{{ majorItem.PersonalScoreDiffOfYear1 }}</span>
                                                    </span>
                                                    <span>平均分：{{majorItem.ScoreAvgOfYear1}}</span>
                                                    <span>平均位次：{{majorItem.RankAvgOfYear1}}</span>
                                                </div>
                                                <div class="ls">
                                                    <span>录取人数：{{majorItem.PersonsOfYear2}}</span>
                                                    <span>最低分：{{majorItem.ScoreLowOfYear2}}</span>
                                                    <span>最低位次：{{majorItem.RankLowOfYear2}}</span>
                                                    <span class="tips">
                                                        <span class="tips-text">
                                                            <!-- <i class="icon el-icon-question"></i> -->
                                                            <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                            <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                                        </span>
                                                        <span>等效分差：{{ majorItem.PersonalScoreDiffOfYear2 }}</span>
                                                    </span>
                                                    <span>平均分：{{majorItem.ScoreAvgOfYear2}}</span>
                                                    <span>平均位次：{{majorItem.RankAvgOfYear2}}</span>
                                                </div>
                                                <div class="ls">
                                                    <span>录取人数：{{majorItem.PersonsOfYear3}}</span>
                                                    <span>最低分：{{majorItem.ScoreLowOfYear3}}</span>
                                                    <span>最低位次：{{majorItem.RankLowOfYear3}}</span>
                                                    <span class="tips">
                                                        <span class="tips-text">
                                                            <!-- <i class="icon el-icon-question"></i> -->
                                                            <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                            <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                                        </span>
                                                        <span>等效分差：{{ majorItem.PersonalScoreDiffOfYear3 }}</span>
                                                    </span>
                                                    <span>平均分：{{majorItem.ScoreAvgOfYear3}}</span>
                                                    <span>平均位次：{{majorItem.RankAvgOfYear3}}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <i class="el-icon-top" title="向上排序" @click.prevent="collegeMajorSortUp(index, majorIndex)"></i>
                                                <i class="el-icon-bottom" title="向下排序" @click.prevent="collegeMajorSortDown(index, majorIndex)"></i>
                                                <i class="el-icon-delete" title="移除专业" @click.prevent="collegeMajorRemove(index, majorIndex)"></i>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <div class="nowish" v-show="false">暂无数据！</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 志愿列表（专业+院校） -->
        <div class="wish_t_h" v-if="CollegeOrMajorGroupId==2">
            <div class="wish_t_fixed" :class="TableHeaderFixed">
                <div class="wish_head">
                    <div class="htable">
                        <div class="head majorgroup">
                            <div class="td">序号</div>
                            <div class="td">专业</div>
                            <div class="td">院校</div>
                            <div class="td">{{SelectRecommendBatch.PlanYear}} 招生计划</div>
                            <div v-if="SelectRecommendBatch.YearList" class="td">
                                <div class="t">历年录取</div>
                                <div class="year">
                                    <div class="y">{{SelectRecommendBatch.YearList[0]}}年</div>
                                    <div class="y">{{SelectRecommendBatch.YearList[1]}}年</div>
                                    <div class="y">{{SelectRecommendBatch.YearList[2]}}年</div>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="wish_t_l" v-if="CollegeOrMajorGroupId==2">
            <div class="wish_t_m">
                <div class="wtable">
                    <div class="table majorgroup">
                        <table cellspacing="0" style="width:100%;" id="WishSortTable">
                            <tr v-for="(item, index) in ReportCheckData" :key="item.RecommendMajorId">
                                <td>
                                    <i class="el-icon-top" title="向上排序" @click.prevent="WishMajorSortUp(item, index)"></i>
                                    <span class="num" title="修改排序" @click.prevent="UpdateSort(index+1, index)">{{index+1}}</span>
                                    <i class="el-icon-bottom" title="向下排序" @click.prevent="WishMajorSortDown(item, index)"></i>
                                    <i class="el-icon-delete" title="移除院校及专业" @click.prevent="WishMajorRemove(item, index)"></i>
                                </td>
                                <td class="move">
                                    <div class="major">
                                        <div class="name">{{item.MajorName}}</div>
                                        <span class="score" v-if="User.wishCategory===1">预测分：{{item.PredictScore?item.PredictScore+'分':'-'}}</span>
                                        <span class="score" v-else>录取概率：{{item.AdmissionProbability?item.AdmissionProbability+'%':'-'}}</span>
                                        <div class="mx">{{item.MajorRemark}}</div>
                                        <div class="mx"><span>代码：{{item.MajorCode}}</span>
                                            <div class="tips-words">
                                                <span v-show="item.PostgraduateRate">读研率：{{ item.PostgraduateRate }}%</span>
                                                <span v-show="item.PostgraduateRecommendationRate">保研率：{{ item.PostgraduateRecommendationRate }}%</span>
                                                <span v-show="item.IsPostgraduateMaster">硕士点：{{ item.IsPostgraduateMaster }}</span>
                                                <span v-show="item.IsPostgraduateDoctoral">博士点：{{ item.IsPostgraduateDoctoral }}</span>
                                                <span v-show="item.MajorRankNum">专业排名：{{item.MajorRankNum}}</span>
                                                <span v-show="item.LearnSubjectEvaluateLevel" class="evaluate">学科评估：{{item.LearnSubjectEvaluateLevel}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="move">
                                    <div class="c_name">{{item.CollegeName||(item.FromCollege&&item.FromCollege.CollegeName)||''}}</div>
                                    <span v-show="item.CollegeRankNum" class="tips-words">{{item.CollegeRankNum+'['+item.CollegeRankCategoryName+']'}}</span>
                                    <div class="s_txt">
                                        <span>代码：{{item.CollegeCode||(item.FromCollege&&item.FromCollege.CollegeCode)||''}}</span>
                                        <span class="types">{{handleNature(item)}}</span>
                                        <span class="tipss" :title="item.CollegeStrengthTags||(item.FromCollege&&item.FromCollege.CollegeStrengthTags)||''">
                                            <span v-for="tagItem in handleTag(item)" :key="tagItem">{{tagItem}}</span>
                                        </span>
                                    </div>
                                </td>
                                <td class="move">
                                    <div v-show="item.PlanPersons" class="person"><span>{{item.PlanPersons}}</span> 人</div>
                                    <div>
                                        <span v-show="item.LearningYears" class="md">{{item.LearningYears}}年</span>
                                        <span v-show="item.LearningCost" class="md"> ￥{{item.LearningCost}}</span>
                                    </div>
                                    <div v-show="item.SubjectsCondition" class="md">{{item.SubjectsCondition}}</div>
                                </td>
                                <td class="move">
                                    <div class="ls">
                                        <span>录取人数：{{item.PersonsOfYear1}}</span>
                                        <span>最低分：{{item.ScoreLowOfYear1}}</span>
                                        <span>最低位次：{{item.RankLowOfYear1}}</span>
                                        <span class="tips">
                                            <span class="tips-text">
                                                <!-- <i class="icon el-icon-question"></i> -->
                                                <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                            </span>
                                            <span>等效分差：{{ item.PersonalScoreDiffOfYear1 }}</span>
                                        </span>
                                        <span>平均分：{{item.ScoreAvgOfYear1}}</span>
                                        <span>平均位次：{{item.RankAvgOfYear1}}</span>
                                    </div>
                                    <div class="ls">
                                        <span>录取人数：{{item.PersonsOfYear2}}</span>
                                        <span>最低分：{{item.ScoreLowOfYear2}}</span>
                                        <span>最低位次：{{item.RankLowOfYear2}}</span>
                                        <span class="tips">
                                            <span class="tips-text">
                                                <!-- <i class="icon el-icon-question"></i> -->
                                                <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                            </span>
                                            <span>等效分差：{{ item.PersonalScoreDiffOfYear2 }}</span>
                                        </span>
                                        <span>平均分：{{item.ScoreAvgOfYear2}}</span>
                                        <span>平均位次：{{item.RankAvgOfYear2}}</span>
                                    </div>
                                    <div class="ls">
                                        <span>录取人数：{{item.PersonsOfYear3}}</span>
                                        <span>最低分：{{item.ScoreLowOfYear3}}</span>
                                        <span>最低位次：{{item.RankLowOfYear3}}</span>
                                        <span class="tips">
                                            <span class="tips-text">
                                                <!-- <i class="icon el-icon-question"></i> -->
                                                <img class="icon" src="@/assets/wish/question.svg" alt="">
                                                <span class="show-text">等效分差根据当前考生成绩通过历年一分一段换算成历年的等效成绩与最低录取分数的差值，换算后的等效分差可以更精确的与历年分数进行比较</span>
                                            </span>
                                            <span>等效分差：{{ item.PersonalScoreDiffOfYear3 }}</span>
                                        </span>
                                        <span>平均分：{{item.ScoreAvgOfYear3}}</span>
                                        <span>平均位次：{{item.RankAvgOfYear3}}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <div class="nowish" v-show="false">暂无数据！</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import Sortable from 'sortablejs';
import API from "@/api/config";
import { getStore, setStore, removeStore } from "@/utils/utils";
export default {
    components:{
        PHeader,
        PFooter
    },
    data() {
        return{
            TableHeaderFixed:'',
            CollegeOrMajorGroupId:2,

            LoginInfo: null,
            User: {},
            RecommendBatch: {},
            SelectRecommendBatch: {},
            ProvinceObj: {},
            SelectWishWayObj: {},

            ReportCheckData: [],

            SaveQuery: {},

            SortGroupType:null,

            selected: '',

            modifyFlag: false
        }
    },
    mounted(){
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            if (ddeht > 410) {
                this.TableHeaderFixed='fixed';
            }
            else {
                this.TableHeaderFixed='';
            }
        }, true);

        this.initial()

        // 院校拖动排序
        this.$nextTick(() => {

            let that = this;
            Sortable.create(document.getElementById('WishSortTable'),{
                animation: 300,
                easing: "cubic-bezier(1, 0, 0, 1)", // 移动动画。默认为 null。例如 https://easings.net/。
                fallbackTolerance: 50, // 以像素为单位指定鼠标在被视为拖动之前应移动的距离
                swapThreshold: 50, // 交换区域的阈值
                onEnd({newIndex, oldIndex}){
                    // 拖动结束（重置排序）
                    let temp = that.ReportCheckData.splice(oldIndex, 1)
                    that.ReportCheckData.splice(newIndex, 0, ...temp)
                }
            });
        })
    },
    methods:{
        // 快速排序 选择
        FastSortCheck(item){
            this.selected = item
            if (item==11) {
				this.ReportCheckData = this.handleAPSort(this.ReportCheckData, true)
			}
			if (item==12) {
				this.ReportCheckData = this.handleAPSort(this.ReportCheckData, false)
			}
			if (item==21) { // 院校排名
				let temp1 = this.ReportCheckData.filter(it => it.CollegeRankNum)
				let temp2 = this.ReportCheckData.filter(it => !it.CollegeRankNum)
				temp1.sort(this.handleSortList('CollegeRankNum', true))
				
				temp2 = this.handleAPSort(temp2, false)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
			if (item==22) { // 院校排名
				let temp1 = this.ReportCheckData.filter(it => it.CollegeRankNum)
				let temp2 = this.ReportCheckData.filter(it => !it.CollegeRankNum)
				temp1.sort(this.handleSortList('CollegeRankNum', false))
				
				temp2 = this.handleAPSort(temp2, true)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
			if (item==31) { // 保研率
				let temp1 = this.ReportCheckData.filter(it => it.PostgraduateRecommendationRate)
				let temp2 = this.ReportCheckData.filter(it => !it.PostgraduateRecommendationRate)
				temp1.sort(this.handleSortList('PostgraduateRecommendationRate', true))
				
				temp2 = this.handleAPSort(temp2, true)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
			if (item==32) { // 保研率
				let temp1 = this.ReportCheckData.filter(it => it.PostgraduateRecommendationRate)
				let temp2 = this.ReportCheckData.filter(it => !it.PostgraduateRecommendationRate)
				temp1.sort(this.handleSortList('PostgraduateRecommendationRate', false))
				
				temp2 = this.handleAPSort(temp2)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
			
			if (item==41) { // 读研率
				let temp1 = this.ReportCheckData.filter(it => it.PostgraduateRate)
				let temp2 = this.ReportCheckData.filter(it => !it.PostgraduateRate)
				temp1.sort(this.handleSortList('PostgraduateRate', true))
				
				temp2 = this.handleAPSort(temp2, true)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
			if (item==42) { // 读研率
				let temp1 = this.ReportCheckData.filter(it => it.PostgraduateRate)
				let temp2 = this.ReportCheckData.filter(it => !it.PostgraduateRate)
				temp1.sort(this.handleSortList('PostgraduateRate', false))
				
				temp2 = this.handleAPSort(temp2)
				
				this.ReportCheckData = temp1.concat(temp2)
			}

            if (this.SelectWishWayObj.WishWay==2) {
				
				this.handleLearnSubject(item)
				
			} else {

				// 院校中抽出专业
				this.ReportCheckData.forEach(itemL => {
					let t1 = itemL.SelectMajor.filter(it => it.LearnSubjectEvaluateLevelNum)
					if (t1.length>0) {
						t1.sort(this.handleSortList('LearnSubjectEvaluateLevelNum', false))
						itemL.LearnSubjectEvaluateLevelNum = t1[0].LearnSubjectEvaluateLevelNum
					} else {
						itemL.LearnSubjectEvaluateLevelNum = null
					}
				})
				this.handleLearnSubject(item)
			}
        },
        handleLearnSubject(item) {

			if (item==51) { // 学科评估
				let temp1 = this.ReportCheckData.filter(it => it.LearnSubjectEvaluateLevelNum)
				let temp2 = this.ReportCheckData.filter(it => !it.LearnSubjectEvaluateLevelNum)
				temp1.sort(this.handleSortList('LearnSubjectEvaluateLevelNum', false))
				
				temp2 = this.handleAPSort(temp2, false)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
			if (item==52) { // 学科评估
				let temp1 = this.ReportCheckData.filter(it => it.LearnSubjectEvaluateLevelNum)
				let temp2 = this.ReportCheckData.filter(it => !it.LearnSubjectEvaluateLevelNum)
				temp1.sort(this.handleSortList('LearnSubjectEvaluateLevelNum', true))
				
				temp2 = this.handleAPSort(temp2, true)
				
				this.ReportCheckData = temp1.concat(temp2)
			}
		},
		handleSortList(property, order=true) {
			return function (a,b) {
				let v1 = a[property]
				let v2 = b[property]
				return order?v1 - v2:v2 - v1
			}
		},
		handleAPSort(list, direction) {
			list = list || []
			if (this.User.wishCategory==0) {

				let arr1 = list.filter(it => it.AdmissionProbability)
				let arr2 = list.filter(it => !it.AdmissionProbability)
				arr1.sort(this.handleSortList('AdmissionProbability', direction||false))
				
				return arr1.concat(arr2)
			} else {
                

                // if (direction==false) direction = true
                // if (direction==true) direction = false

				let arr1 = list.filter(it => it.PredictScore)
				let arr2 = list.filter(it => !it.PredictScore)
				arr1.sort(this.handleSortList('PredictScore', direction||false))
				
				return arr1.concat(arr2)
			}
		},
        initial() {
            this.getInfo()
        },
        getInfo() {
            // 获取推荐批次
            let batch = getStore('RecommendBatch')
            this.RecommendBatch = JSON.parse(batch)
            
            let selectBatch = getStore('SelectRecommendBatch')
            this.SelectRecommendBatch = JSON.parse(selectBatch)

            this.modifyFlag = getStore('modifyFlag')
            if (this.modifyFlag) {
                this.modifyFlag = JSON.parse(this.modifyFlag)
            } else {
                this.modifyFlag = false
            }
            
            // 获取选中省份
            let province = getStore('OrgProvince')
            if (!province) {
                this.$message.error('未获取到省份数据！')
                return false
            }
            this.ProvinceObj = JSON.parse(province)

            if (this.modifyFlag) {
                let modifyProvinceObj = getStore('modifyProvinceObj')
                this.ProvinceObj = JSON.parse(modifyProvinceObj)
            }

            // 判断是院校专业组还是专业院校组
            
            this.SelectWishWayObj = this.ProvinceObj.WishWayDic[this.SelectRecommendBatch.BatchId]
            this.CollegeOrMajorGroupId = this.SelectWishWayObj.WishWay

            // login info
            let login = getStore('LoginData')
            if (login) {
                this.LoginInfo = JSON.parse(login)
            }
            // 获取个人信息
            let tempUser = getStore('studentReport')
            this.User = JSON.parse(tempUser)

            // 已填列表数据
            this.getWishReport()
        },
        // 获取志愿标数据
        getWishReport() {
            let ReportCheckDataObj = getStore('ReportCheckDataObj')
            if (ReportCheckDataObj) {
                this.ReportCheckData = JSON.parse(ReportCheckDataObj)[this.SelectWishWayObj.WishWay]

                if (this.SelectWishWayObj.WishWay==2) {
                    this.ReportCheckData = this.ReportCheckData.map(it => {
                        if (it.FromCollege) {
                            it = {...it.FromCollege, ...it}
                        }
                        
                        return it
                    })
                }
            }
        },
        // 院校组相关
        CollegeWishSortUp(it, i) {
            if (i <= 0) return false
            let temp = this.ReportCheckData.splice(i-1, 1, this.ReportCheckData[i])
            this.ReportCheckData.splice(i, 1, ...temp)
        },
        CollegeWishSortDown(it, i) {
            if (i >= this.ReportCheckData.length-1) return false
            let temp = this.ReportCheckData.splice(i+1, 1, this.ReportCheckData[i])
            this.ReportCheckData.splice(i, 1, ...temp)
        },
        CollegeWishRemove(it, i) {
            this.ReportCheckData.splice(i, 1)
        },
        collegeMajorSortUp(i, mi) {
            if (mi <= 0) return false
            let temp = this.ReportCheckData[i].SelectMajor.splice(mi-1, 1, this.ReportCheckData[i].SelectMajor[mi])
            this.ReportCheckData[i].SelectMajor.splice(mi, 1, ...temp)
        },
        collegeMajorSortDown(i, mi) {
            if (mi >= this.ReportCheckData[i].SelectMajor.length-1) return false
            let temp = this.ReportCheckData[i].SelectMajor.splice(mi+1, 1, this.ReportCheckData[i].SelectMajor[mi])
            this.ReportCheckData[i].SelectMajor.splice(mi, 1, ...temp)
        },
        collegeMajorRemove(i, mi) {
            this.ReportCheckData[i].SelectMajor.splice(mi, 1)
            if (!this.ReportCheckData[i].SelectMajor || this.ReportCheckData[i].SelectMajor.length <= 0) {
                this.ReportCheckData.splice(i, 1)
            }
        },
        // 专业+相关
        WishMajorSortUp (it, i) {
            if (i <= 0) return false
            let temp = this.ReportCheckData.splice(i-1, 1, this.ReportCheckData[i])
            this.ReportCheckData.splice(i, 1, ...temp)
        },
        WishMajorSortDown (it, i) {
            if (i >= this.ReportCheckData.length-1) return false
            let temp = this.ReportCheckData.splice(i+1, 1, this.ReportCheckData[i])
            this.ReportCheckData.splice(i, 1, ...temp)
        },
        WishMajorRemove (it, i) {
            this.ReportCheckData.splice(i, 1)
        },
        // WishMajorUpdateSort () {

        // },
        // 快速排序切换
        // SortGroupTypeChange(val){
        //     this.SortGroupType = val;
        //     // console.log(val, 'vvv')
        //     this.FastSortCheck(val)
        // },
        // 修改排序
        UpdateSort(index, i){
            this.$prompt('请输入正整数', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^[1-9]\d*$/,
                inputErrorMessage: '只能输入正整数',
                inputValue:index
            }).then(({ value }) => {
                value = value - 1
                if (value > this.ReportCheckData.length - 1) {
                    value = this.ReportCheckData.length - 1
                }
                
                let temp = this.ReportCheckData.splice(i, 1)
                this.ReportCheckData.splice(value, 0, ...temp)
            }).catch(() => {
            });
        },
        // 向上排序
        SortUp(){

        },
        // 向下排序
        SortDown(){

        },
        // 下载（PDF）
        WishDownload(){
            window.open(this.$router.resolve({path: '/download?group='+this.CollegeOrMajorGroupId+'&type=1'}).href, "_blank");
        },
        // 打印
        WishPrint(){
            window.open(this.$router.resolve({path: '/download?group='+this.CollegeOrMajorGroupId+'&type=2'}).href, "_blank");
        },
        // 编辑
        WishEdit(){
            let obj = {}
            if (this.SelectWishWayObj.WishWay === 1) {
                obj = {
                    1: this.ReportCheckData
                }
            }
            if (this.SelectWishWayObj.WishWay === 2) {
                obj = {
                    2: this.ReportCheckData
                }
            }
            setStore('ReportCheckDataObj', obj)
            
            let p = this.$route.query.WishId
            if (p) {
                this.$router.push("/recommend-index?WishId="+p);
            } else {
                this.$router.push("/recommend-index");
            }
        },
        // 保存
        WishSave(){
            if (!this.ReportCheckData || this.ReportCheckData.length<=0) {
                this.$message.warning('暂无需要保存的数据！')

                return
            }
            if (!this.LoginInfo) {
                this.$message.error('未获取到登录信息！')
                return false
            }
            
            this.SaveQuery.UserId = this.LoginInfo.UserId
            this.SaveQuery.ExamProvinceId = this.User.ProvinceId
            this.SaveQuery.ExamProvinceName = this.User.ProvinceName || null
            this.SaveQuery.ExamYear = this.User.Year
            this.SaveQuery.SubjectType = this.User.SubjectType
            this.SaveQuery.Subjects = this.User.SubjectList?(this.User.SubjectList.join()):null
            this.SaveQuery.BatchId = this.SelectRecommendBatch.BatchId
            this.SaveQuery.BatchName = this.SelectRecommendBatch.BatchName || null
            this.SaveQuery.ZYWay = this.SelectWishWayObj.WishWay
            this.SaveQuery.ZYType = this.User.wishCategory
            this.SaveQuery.ZYName = '' || null
            this.SaveQuery.Score = this.User.Score
            this.SaveQuery.RankHigh = this.RecommendBatch.RankHigh
            this.SaveQuery.RankLow = this.RecommendBatch.RankLow
            this.SaveQuery.Rank = this.RecommendBatch.Rank || null
            this.SaveQuery.ScoreDiff = this.User.Score - this.SelectRecommendBatch.BatchScore
            this.SaveQuery.PlanYear = this.SelectRecommendBatch.PlanYear
            this.SaveQuery.RecommendVersion = this.SelectRecommendBatch.RecommendVersion
            this.SaveQuery.RecommendKey = this.SelectRecommendBatch.RecommendKey

            this.SaveQuery.YearList = this.SelectRecommendBatch.YearList

            this.SaveQuery.ScoreEqualDic = this.SelectRecommendBatch.ScoreEqual || null
            this.SaveQuery.RecordList = []

            if (this.$route.query.WishId) {
                this.SaveQuery.Id = this.$route.query.WishId
            } else {
                delete this.SaveQuery.Id
            }

            this.handleParams()
        },

        handleParams() {
            // 1
            if (this.CollegeOrMajorGroupId === 1) {
                
                this.ReportCheckData.map((item, index) => {
                    let data = []
                    
                    item.SelectMajor.map((sItem, sIndex) => {

                        delete item.MajorPredictScoreMin
                        delete item.MajorPredictScoreMax

                        data.push({MajorNum: sIndex+1, ...sItem})
                    })
                    this.SaveQuery.RecordList.push({...item, MajorList:data, ZYNum: index+1})
                })

                this.$prompt('请输入志愿名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.SaveQuery.ZYName = value
                    
                    API.Recommend.SaveZY(this.SaveQuery).then(res => {

                        if (res.code==0) {
                            // removeStore('ReportCheckDataObj')
                            let obj = {}
                            if (this.SelectWishWayObj.WishWay === 1) {
                                obj = {
                                    1: this.ReportCheckData
                                }
                            }
                            if (this.SelectWishWayObj.WishWay === 2) {
                                obj = {
                                    2: this.ReportCheckData
                                }
                            }
                            setStore('ReportCheckDataObj', obj)

                            this.$message.success('志愿保存成功')
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })

                // API.Recommend.SaveZY
            }

            // 2
            if (this.CollegeOrMajorGroupId === 2) {
                
                this.ReportCheckData.map((item, index) => {
                    if (item.FromCollege) {
                        item = {...item.FromCollege, ...item}
                    }
                    delete item.FromCollege

                    this.SaveQuery.RecordList.push({...item, ZYNum: index+1})
                })

                this.$prompt('请输入志愿名称', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.SaveQuery.ZYName = value
                    
                    API.Recommend.SaveZYMajorAndCollege(this.SaveQuery).then(res => {
                        if (res.code==0) {
                            // removeStore('ReportCheckDataObj')

                            let obj = {}
                            if (this.SelectWishWayObj.WishWay === 1) {
                                obj = {
                                    1: this.ReportCheckData
                                }
                            }
                            if (this.SelectWishWayObj.WishWay === 2) {
                                obj = {
                                    2: this.ReportCheckData
                                }
                            }
                            setStore('ReportCheckDataObj', obj)
                            
                            this.$message.success('志愿保存成功')
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })

                // API.Recommend.SaveZYMajorAndCollege
            }
        },

        // 标签
        handleTag(item) {
            let temp = item.CollegeStrengthTags || (item.FromCollege && item.FromCollege.CollegeStrengthTags)
            return temp ? temp.split(','):[]
        },
        handleNature(item) {
            let temp1 = item.CollegeProvinceName || (item.FromCollege && item.FromCollege.CollegeProvinceName)
            let temp2 = item.CollegeCityName || (item.FromCollege && item.FromCollege.CollegeCityName)
            let temp3 = item.CollegeCategoryName || (item.FromCollege && item.FromCollege.CollegeCategoryName)
            let temp4 = item.CollegeNatureName || (item.FromCollege && item.FromCollege.CollegeNatureName)

            let data = `${temp1||''} · ${temp2||''} · ${temp3||''} · ${temp4||''}`
            return data
        },
    }
}
</script>
<style scoped lang="less">
.wish_table{
    max-width: 1420px;
    margin: 20px auto 0px;
    user-select: none;
    .wish_box{
        margin: 0px 20px;
        border: 1px solid #dfdddd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom: none;
        .mark{
            border: 1px dashed rgba(var(--themecolor),0.6);
            border-radius: 5px;
            padding: 5px 0;
            margin: 15px;
            background-color: #fff;
            span{
                display: inline-block;
                margin: 3px 15px;
                color: #777;
                font-size: 13px;
            }
        }
        .userbox{
            margin: 20px 20px 10px;
            background-color: #fff;
            border-radius: 5px;
            .type{
                padding: 20px 20px 0;
                width: calc(100% - 468px);
                display: inline-block;
                .vip{
                    border: 3px solid rgba(var(--themecolor),0.3);
                    color: #da8824;
                    font-size: 16px;
                    padding: 2px 15px;
                    border-radius: 5px;
                    display: inline-block;
                    letter-spacing: 2px;
                    font-weight: bold;
                    font-style: italic;
                    position: relative;
                    i{
                        text-align: center;
                        color: #e6b738;
                        font-size: 20px;
                        margin-right: 5px;
                        position: absolute;
                        left: -11px;
                        top: -16px;
                        z-index: 1;
                    }
                }
                .tname{
                    font-size: 20px;
                    color: #333;
                    margin-left: 20px;
                    span{
                        color: #666;
                    }
                }
                .yname{
                    font-size: 16px;
                    color: #555;
                    margin-left: 20px;
                }
            }
            .info{
                width: calc(100% - 468px);
                margin: 20px 20px 20px;
                padding-bottom: 10px;
                display: inline-block;
                .exam{
                    font-size: 14px;
                    color: #555;
                    span{
                        margin-right: 20px;
                    }
                }
            }
            .bott{
                width: 428px;
                height: 40px;
                float: right;
                span{
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                    margin: 0px 10px;
                    background-color: rgba(var(--themecolor),0.8);
                    color: #fff;
                    font-size: 14px;
                    display: inline-block;
                    padding: 0 20px;
                    cursor: pointer;
                    i{
                        margin-right: 5px;
                    }
                }
                span:hover{
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .theadtitle{
            margin: 0 20px;
            padding-bottom: 5px;
            .txt{
                color: #666;
                font-size: 14px;
                line-height: 40px;
                b{
                    color: rgba(var(--themecolor),1);
                    font-size: 18px;
                }
            }
            .sort{
                float: right;
                height: 40px;
                line-height: 40px;
                .tit{
                    color: #666;
                    font-size: 14px;
                    height: 40px;
                    float: left;
                    display: block;
                    margin-left: 15px;
                    .ico{
                        margin-right: 3px;
                        // color: #b6b6b6;
                        color: rgba(var(--themecolor),0.5);
                        font-size: 16px;
                        margin-right: 8px;
                    }
                    .tt{
                        float: left;
                        display: block;
                        height: 40px;
                        border-left: 1px dashed #c0bebe;
                        padding-left: 18px;
                    }
                    .icon{
                        float: left;
                        display: block;
                        width: 26px;
                        height: 40px;
                        i{
                            width: 100%;
                            height: 20px;
                            line-height: 20px;
                            text-align: center;
                            display: block;
                            font-size: 20px;
                            color: #c9c9c9;
                        }
                        i.on{
                            color: rgba(var(--themecolor),0.9);
                        }
                        i:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.8);
                        }
                        i:first-child{
                            line-height: 24px;
                        }
                        i:last-child{
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }
}
.wish_t_h{
    width: 100%;
    height: 50px;
    .wish_t_fixed{
        width: 100%;
        height: 50px;
        .wish_head{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            .htable{
                margin: auto 20px;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                .head{
                    margin: 0px 20px;
                    background-color: #f5ecec;
                    height: 49px;
                    line-height: 49px;
                    color: #555;
                    font-size: 15px;
                    border-bottom: 1px solid #ddd;
                    border: 1px solid #ddd;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    .td{
                        text-align: center;
                        border-left: 1px solid #ddd;
                        float: left;
                        .t{
                            border-bottom: 1px solid #ddd;
                            height: 24px;
                            line-height: 24px;
                        }
                        .year{
                            height: 25px;
                            line-height: 25px;
                            .y{
                                float: left;
                                width: calc((100% - 2px) / 3);
                                border-left: 1px solid #ddd;
                                font-size: 14px;
                                color: #777;
                            }
                            .y:first-child{
                                border-left:none;
                            }
                        }
                    }
                    .td:nth-child(1){
                        width: 50px;
                        border-left:none;
                    }
                    .td:nth-child(2){
                        width: 240px;
                    }
                    .td:nth-child(3){
                        width: 300px;
                    }
                    .td:nth-child(4){
                        width: 150px;
                    }
                    .td:nth-child(5){
                        width: calc(100% - 785px);
                    }
                    .td:nth-child(6){
                        width: 40px;
                    }
                }
                .majorgroup{
                    .td:nth-child(2){
                        width: 300px;
                    }
                    .td:nth-child(3){
                        width: 300px;
                    }
                    .td:nth-child(4){
                        width: 150px;
                    }
                    .td:nth-child(5){
                        width: calc(100% - 804px);
                    }
                }
            }
        }
    }
    .wish_t_fixed.fixed{
        position: fixed;
        top: 40px;
        left: 0px;
        z-index: 99;
    }
}
.wish_t_l{
    width: 100%;
    .wish_t_m{
        max-width: 1420px;
        margin: auto;
        user-select: none;
        .wtable{
            margin: 0px 20px 50px;
            border: 1px solid #ddd;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .table{
                margin: 0px 20px 20px;
                border: 1px solid #ddd;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                background-color: #fff;
                // .move {
                //     &:nth-child(1) {
                //         width: 200px;
                //     }
                // }
                tr td.move:hover{
                    cursor: move;
                }
                tr{
                    color: #666;
                    font-size: 14px;
                    td{
                        padding: 0 5px;
                        border-left: 1px solid #ddd;
                        border-bottom: 1px solid #ddd;
                        .c_name{
                            color: #555;
                            font-size: 20px;
                            line-height: 24px;
                        }
                        .s_txt{
                            margin-top: 10px;
                            font-size: 12px;

                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            
                            span{
                                margin: 5px 5px;
                                // display: block;
                            }
                            .types{
                                font-size: 12px;
                                display: block;
                                line-height: 24px;
                            }
                            .tipss{
                                font-size: 12px;
                                color: #888;
                                margin-top: 5px;
                                overflow: hidden;
                                display: inline-block;
                                height: 20px;
                                span{
                                    padding: 0 5px;
                                    border-left: 1px solid #ddd;
                                    display: inline-block;
                                    height: 12px;
                                    line-height: 12px;
                                }
                                span:first-child{
                                    border-left:none;
                                    padding-left: 0;
                                }
                            }
                        }
                        .num{
                            display: inline-block;
                            width:36px;
                            height: 18px;
                            text-align: center;
                            line-height: 18px;
                            background-color: #fff;
                            color: rgba(var(--themecolor),1);
                            font-size: 14px;
                            border: 1px solid rgba(var(--themecolor),0.25);
                            border-radius: 3px;
                            overflow: hidden;
                            i{
                                font-size: 12px;
                                color: #999;
                                margin-left: 2px;
                            }
                        }
                        .num:hover{
                            cursor: pointer;
                            border: 1px solid rgba(var(--themecolor),0.4);
                        }
                        .major{
                            padding: 10px 10px;
                            .name{
                                color: #555;
                                font-size: 18px;
                                line-height: 22px;
                                margin-bottom: 5px;
                            }
                            .mx{
                                font-size: 12px;
                                margin: 5px 0;
                                span{
                                    margin-right:10px;
                                }
                            }
                        }
                        .score{
                            font-size: 14px;
                            color: rgba(var(--themecolor),1);
                            display: block;
                            .isadjuststr{
                                margin-left: 10px;
                                font-size: 12px;
                                color: #666;
                            }
                        }
                    }
                    td:nth-child(1){
                        width: 40px;
                        border-left:none;
                        text-align: center;
                        position: relative;
                        i.el-icon-top,i.el-icon-bottom{
                            display: inline-block;
                            font-size: 18px;
                            color: #999;
                            width: 22px;
                            height: 22px;
                            line-height: 22px;
                        }
                        i.el-icon-top:hover,i.el-icon-bottom:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor),1);
                        }
                        i.el-icon-delete{
                            width: 22px;
                            height: 22px;
                            line-height: 22px;
                            font-size: 14px;
                            margin-top: 10px;
                            color: #999;
                            display: inline-block;
                            cursor: pointer;
                        }
                        i.el-icon-delete:hover{
                            color: rgba(var(--themecolor),1);
                        }
                    }
                    td:nth-child(2){
                        width: 240px;
                        text-align: center;
                        padding: 5px 0;
                    }
                    td:nth-child(3){
                        // width: calc(100% - 200px);
                        padding: 0;
                        tr:last-child td{
                            border-bottom:none;
                        }
                        td{
                            border-bottom: 1px solid #ddd;
                            .person{
                                color: #777;
                                font-size: 12px;
                                margin-bottom: 10px;
                                span{
                                    color: #555;
                                    font-size: 18px;
                                }
                            }
                            .md{
                                color: #888;
                                font-size: 12px;
                            }
                        }
                        td:nth-child(1){
                            width: 290px;
                        }
                        td:nth-child(2){
                            width: 150px;
                            border-left: 1px dashed #b8b8b8;
                        }
                        td:nth-child(3){
                            border-left: 1px dashed #b8b8b8;
                            padding: 20px 0;
                            .ls{
                                width: calc((100% - 2px) / 3);
                                display: inline-block;
                                border-left: 1px dashed #b8b8b8;
                                span{
                                    display: block;
                                    line-height: 20px;
                                    text-align: center;
                                    color: #666;
                                    font-size: 12px;
                                }
                            }
                            .ls:first-child{
                                border-left:none;
                            }

                            .tips {
                                display: flex;
                                span {
                                    display: inline;
                                }
                            }
                            .tips-text {
                                position: relative;
                                .icon {
                                    height: 13px;
                                    color: #999;
                                }
                                &:hover {
                                    cursor: default;
                                    .show-text {
                                        display: block;
                                        cursor: default;
                                        text-align: left;
                                    }
                                }
                                .show-text {
                                    display: none;
                                    width: 200px;
                                    padding: 5px;
                                    border-radius: 5px;
                                    background-color: rgba(0,0,0,0.5);
                                    color: #fff;
                                    position: absolute;
                                    top: -88px;
                                    left: -100px;
                                    z-index: 2;
                                }
                            }
                            
                        }
                        td:nth-child(4){
                            width: 30px;
                            text-align: center;
                            i.el-icon-top,i.el-icon-bottom{
                                display: inline-block;
                                font-size: 18px;
                                color: #999;
                                width: 22px;
                                height: 22px;
                                line-height: 22px;
                            }
                            i.el-icon-top:hover,i.el-icon-bottom:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),1);
                            }
                            i.el-icon-top{
                                margin-bottom: 5px;
                            }
                            i.el-icon-bottom{
                                margin-top: 5px;
                            }
                            i.el-icon-delete{
                                width: 22px;
                                height: 22px;
                                line-height: 22px;
                                font-size: 14px;
                                margin-top: 8px;
                                color: #999;
                                display: inline-block;
                                cursor: pointer;
                            }
                            i.el-icon-delete:hover{
                                color: rgba(var(--themecolor),1);
                            }
                        }
                    }
                }
                tr:last-child td{
                    border-bottom:none;
                }
            }
            .majorgroup{
                tr td{
                    text-align: center;
                    .person{
                        color: #777;
                        font-size: 12px;
                        margin-bottom: 10px;
                        span{
                            color: #555;
                            font-size: 18px;
                        }
                    }
                    .md{
                        color: #888;
                        font-size: 12px;
                    }
                    .score{
                        font-size: 14px;
                        color: rgba(var(--themecolor),1);
                        display: block;
                    }
                }
                tr td:nth-child(1){
                    i.el-icon-top{
                        margin-bottom: 5px;
                    }
                    i.el-icon-bottom{
                        margin-top: 5px;
                    }
                    i.el-icon-delete{
                        margin-top: 5px;
                    }
                }
                tr td:nth-child(2){
                    width: 300px;
                }
                // tr td:nth-child(2){
                //     width: 290px;
                // }
                tr td:nth-child(3){
                    width: 280px;
                    padding: 0px 10px;
                }
                tr td:nth-child(4){
                    width: 140px;
                }
                tr td:nth-child(5){
                    padding: 10px 0;
                    .ls{
                        width: calc((100% - 2px) / 3);
                        display: inline-block;
                        border-left: 1px dashed #b8b8b8;
                        span{
                            display: block;
                            line-height: 20px;
                            text-align: center;
                            color: #666;
                            font-size: 12px;
                        }
                    }
                    .ls:first-child{
                        border-left:none;
                    }
                    .tips {
                        display: flex;
                        span {
                            display: inline;
                        }
                    }
                    .tips-text {
                        position: relative;
                        .icon {
                            height: 13px;
                            color: #999;
                        }
                        &:hover {
                            cursor: default;
                            .show-text {
                                display: block;
                                cursor: default;
                            }
                        }
                        .show-text {
                            display: none;
                            width: 200px;
                            padding: 5px;
                            border-radius: 5px;
                            background-color: rgba(0,0,0,0.5);
                            color: #fff;
                            position: absolute;
                            top: -88px;
                            left: -100px;
                            z-index: 2;
                        }
                    }
                }
            }
            .nowish{
                width: 100%;
                text-align: center;
                line-height: 300px;
                color: #666;
                font-size: 16px;
            }
        }
    }
}
</style>
<style lang="less">
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:focus, .el-message-box__wrapper .el-message-box .el-message-box__btns .el-button:hover {
  color: rgba(var(--themecolor),0.75) !important;
  border-color: rgba(var(--themecolor),0.35) !important;
  background-color: rgba(var(--themecolor),0.01) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary{
    background-color: rgba(var(--themecolor),0.75) !important;
    border-color: rgba(var(--themecolor),0.75) !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:focus,.el-message-box__wrapper .el-message-box .el-message-box__btns .el-button--primary:hover{
    background-color: rgba(var(--themecolor),0.95) !important;
    border-color: rgba(var(--themecolor),0.95) !important;
    color: #fff !important;
}
.el-message-box__wrapper .el-message-box .el-message-box__header .el-message-box__headerbtn .el-message-box__close:hover{
    color: rgba(var(--themecolor),0.95) !important;
}
.el-message-box__wrapper .el-message-box .el-input.is-active .el-input__inner,.el-message-box__wrapper .el-message-box .el-input__inner:focus{
    border-color: #ddd !important;
}
.wish_table .wish_box .theadtitle .el-radio{
    margin-right: 15px;
    font-size: 14px;
}
.wish_table .wish_box .theadtitle .el-radio__input.is-checked .el-radio__inner{
    border-color: rgba(var(--themecolor),0.8);
    background: rgba(var(--themecolor),0.8);
    box-shadow: none;
}
.wish_table .wish_box .theadtitle .el-radio__inner:hover{
    border-color: rgba(var(--themecolor),0.6);
}
.wish_table .wish_box .theadtitle .el-radio__input.is-checked + .el-radio__label{
    color: rgba(var(--themecolor),0.8);
}
.wish_table .wish_box .theadtitle .el-radio:hover .el-radio__inner{
    border-color: rgba(var(--themecolor),0.6);
}
.wish_table .wish_box .theadtitle .el-radio:hover .el-radio__label{
    color: rgba(var(--themecolor),0.8);
}
</style>